import { HistoricalReport } from '@/models/HistoricalReport';
import { Rpc } from '@/models/Rpc';
import { Skill } from '@/models/Skill';
import { Event, EventTypeLabels, getAppointmentType, EventType } from '@/models/Event';
import { AxiosError } from 'axios';
import { EventRender, renderSimpleEvent } from '@/support/CalendarEventRenderFunctions';
import { AppointmentLimit } from '@/models/AppointmentLimit';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { User, CalendarUser } from '@/models/User';
import { DateTime, Settings } from 'luxon';
import { slotDuration, slotLabelFormat, columnsHeaderFormat, calendarPlugins } from '@/support/FullcalendarSettings';
import ErrorHandler from '@/support/ErrorHandler';
import { Report, Answer } from '@/models/Report';
import { debounce } from 'lodash';
import { Setting } from '@/models/Setting';

@Component<ReplanExpertCalendarDialog>({})
export default class ReplanExpertCalendarDialog extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */

   @Prop()
   protected value!: boolean;

   @Prop()
   protected event!: Event;

   @Prop()
   protected report!: Report;
   // #endregion

   // #region @Refs
   /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
   // #endregion

   // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */
  protected isLoading = false;

  protected availableUsers: CalendarUser[] = [];

  protected users: CalendarUser[] = [];

  protected selectedUser: CalendarUser | null = null;

  protected events: Event[] = [];

  protected settings: Setting[] = [];

  protected historicalDamages: HistoricalReport[] = [];

  protected loadMap: {[key:string] : boolean} = {};

  protected appointmentLimits: AppointmentLimit[] = [];

  protected lastPage = 1;

  protected currentPage = 1;

  protected selectedUserId: string | null = null;

  protected expertSearch = '';

  protected debouncedSearch: Function = debounce(this.handleSearch, 300);
  // #endregion

  // #region Lifecycle Hooks / Init

  protected async mounted(): Promise<void> {
    Settings.defaultLocale = 'nl';
    this.isLoading = true;
    await this.getPlannableUsers();
    await this.getSettings();
    this.isLoading = false;
  }
  // #endregion

  // #region Class methods

  protected close(): void {
    this.$emit('input', false);
  }

  protected findValueAppointmentLimit(expertId: string, date: string) {
    const appointmentLimit = this.appointmentLimits.find((appointmentLimit: AppointmentLimit) => (appointmentLimit.date === date) && (appointmentLimit?.user?.uuid === expertId));

    const settting = this.settings.find((setting) => setting.key === `max_visit_type_${this.event?.appointment_type}`);

    return appointmentLimit ? appointmentLimit.appointment_limit : (settting?.value || '-');
  }

  protected columnsHeaderHtml(user: CalendarUser): (date: any) => string {
    return (date: any) => {
      const limit = this.findValueAppointmentLimit(user.id, DateTime.fromJSDate(date).toFormat('yyyy-LL-dd'));
      return `<p class="fc-custom-header">${DateTime.fromJSDate(date).toFormat('cccc dd LLLL yyyy')}</p><span style="font-weight: 700; padding: 0 !important;">Max ${this.event?.appointment_type ? getAppointmentType(this.event.appointment_type).label : ''}. ${limit}</span>`;
    };
  }

  protected renderEvent(eventInfo: EventRender) {
    renderSimpleEvent(eventInfo);
  }

  protected next() {
    this.currentPage += 1;
    this.getPlannableUsers();
  }

  protected previous() {
    this.currentPage -= 1;
    this.getPlannableUsers();
  }

  protected refreshBusinessHours() {
    this.availableUsers.forEach((user: CalendarUser) => {
      this.refreshBusinessHoursForUser(user);
    });
  }

  protected parseUsersToCalendarUsers(users: User[]): CalendarUser[] {
    const calendarUsers:CalendarUser[] = [];

    users.forEach((user: User) => {
      const calendarUser: CalendarUser = user.parseToCalendarUser();
      const businessHours = user.parseScheduleToBusinessHours();
      calendarUser.businessHours = businessHours;
      calendarUser.organization = this.activeOrganization;
      calendarUsers.push(calendarUser);
    });

    return calendarUsers;
  }

  protected parseEvents(events: Event[]) {
    events.forEach((event: Event) => {
      const user = this.availableUsers.find((user: CalendarUser) => user.id === event.user_id);

      if (user) {
        const fullcalendarEvent: {[key: string]: any} | null = event.fullcalendarEvent;
        if (! fullcalendarEvent) {
          return;
        }

        const hasEvent = user.events.some((currentEvent: any) => currentEvent.id === event.id);

        if (hasEvent) {
          return;
        }

        fullcalendarEvent.extendedProps = {
          type: event.type,
          appointment_type: event.appointment_type,
          user: {
            name: user.name,
            id: user.id,
          },
          organization_id: user.organization,
          report: event.report,
        };

        if (event.type !== EventType.APPOINTMENT) {
          fullcalendarEvent.title = this.eventTypeLabels[event.type as string];
        }

        fullcalendarEvent.durationEditable = false;
        fullcalendarEvent.editable = false;

        user.events.push(fullcalendarEvent);
      }
    });
  }

  protected renderConceptEvent(user: CalendarUser) {
    const start = this.event.starts_at;
    const end = this.event.ends_at;

    if (! start || ! end) {
      // this.loadMap[user.id] = false;
      return;
    }

    // const extendedProps = this.createEventExtendedProps(this.planningValidation.reservation);
    const eventTitle = this.report && this.report.case_number ? ` - ${this.report.case_number}` : '';
    const event = {
      title: `[ Concept afspraak ]${eventTitle}`,
      id: 'myReservation',
      start,
      end,
      // extendedProps,
      className: 'concept-event',
    };

    user.events.push(event);

    // this.unSelectCalendar();

    // this.loadMap[user.id] = false;
  }

  protected selectUser(user: CalendarUser) {
    this.selectedUser = user;
  }

  protected isSelectedUser(user: CalendarUser) {
    return !! this.selectedUser && this.selectedUser.id === user.id;
  }

  protected handleSearch(query: string) {
    if (! query) {
      return;
    }

    this.getUsers();
  }

  protected resetCalendar() {
    this.currentPage = 1;
    this.lastPage = 1;
    this.getPlannableUsers();
  }

  protected loadAvailableUsers(users: User[]) {
    if (users.length) {
      this.currentPage = (users[0].meta as any).current_page;
      this.lastPage = (users[0].meta as any).last_page;
      this.availableUsers = this.parseUsersToCalendarUsers(users);

      this.availableUsers.forEach((user: CalendarUser) => {
        this.renderConceptEvent(user);
      });

      this.getEvents();
      this.refreshBusinessHours();
    } else {
      this.availableUsers = [];
    }
  }
  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */

  protected async getSettings() {
    this.settings = await new Setting()
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getUsers() {
    let filter: {[key:string] : any} = {
      is_plannable: true,
      search: this.expertSearch,
    };

    filter = { ...filter, ...this.userFilter };

    const users = await new User()
      .dmz(this.activeOrganization)
      .filter(filter)
      .include(['schedules', 'skills'])
      .sort('event_count')
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.users = this.parseUsersToCalendarUsers(users);
    this.appointmentLimits = await this.fetchAppointmentLimits(this.activeOrganization);
  }

  protected async getAvailableUser() {
    let filter: {[key:string] : any} = {
      is_plannable: true,
      appointment_type: this.event.appointment_type || '',
    };

    filter = { ...filter, ...this.userFilter };

    const user = await new User()
      .dmz(this.activeOrganization)
      .filter(filter)
      .include(['schedules', 'skills'])
      .sort('event_count')
      .find(this.selectedUserId)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.loadAvailableUsers([user]);
    this.appointmentLimits = await this.fetchAppointmentLimits(this.activeOrganization);
  }

  protected async getPlannableUsers(): Promise<void> {
    let filter: {[key: string]: any} = {
      is_plannable: true,
      type: 'expert',
      appointment_type: this.event.appointment_type || '',
    };

    filter = { ...filter, ...this.userFilter };

    const users = await new User()
      .dmz(this.activeOrganization)
      .include(['schedules', 'skills'])
      .page(this.currentPage)
      .filter(filter)
      .sort('events_and_random')
      .limit(4)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.loadAvailableUsers(users);
    this.appointmentLimits = await this.fetchAppointmentLimits(this.activeOrganization);
  }

  protected async getEvents(): Promise<void> {
    const events = await new Event()
      .dmz(this.activeOrganization)
      .include('report')
      .filter(this.eventsFilter)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (events) {
      this.events = events;
      this.parseEvents(events);
    }

    this.refreshBusinessHours();
  }

  protected async refreshBusinessHoursForUser(currentUser: CalendarUser): Promise<void> {
    const user = await new User()
      .dmz(this.activeOrganization)
      .include(['schedules'])
      .filter('schedule_date_spoof', this.event.date)
      .sort('event_count')
      .find(currentUser.id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    currentUser.businessHours = user.parseScheduleToBusinessHours();
  }

  protected async fetchAppointmentLimits(id: string): Promise<AppointmentLimit[]> {
    const model = id ? new AppointmentLimit().dmz(id) : new AppointmentLimit();
    return await model
      .filter({
        day: this.event.date,
        users: this.availableUsers.map((user: CalendarUser) => user.id),
      })
      .include('user')
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected async update() {
    const payload = {
      signature: 'appointment-update:expert-change',
      body: {
        expert: this.selectedUser?.id,
        event: this.event.id,
      },
    };

    await new Rpc()
      .rpcPost(payload, false)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.close();
    this.$emit('updated');
  }

  protected async getHistoricalReports() {
    if (! this.report) {
      return;
    }
    this.historicalDamages = await new HistoricalReport()
      .filter({ report: this.report.uuid })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
  // #endregion

  // #region Getters & Setters

  protected get userFilter() {
    const filter: {[key: string]: any} = {};

    filter.schedule_date_spoof = this.event.date;

    if (this.report && this.report.address && this.report.address.postcode) {
      filter.postcode = this.report.address.postcode;
    }

    if (this.report && this.report.level) {
      filter.minimum_level = this.report.level;
    }

    if (this.report && this.report.skills && this.report.skills.length) {
      filter.skills = this.report.skills.map((skill: Skill) => skill.id);
    }

    return filter;
  }

  protected get canSelectPrevious(): boolean {
    return this.availableUsers.length > 1 && this.currentPage > 1;
  }

  protected get canSelectNext(): boolean {
    return this.availableUsers.length > 1 && this.currentPage < this.lastPage;
  }

  protected get switcherLabel(): string {
    if (! this.event.date) { return ''; }
    return 'Experts';
  }

  protected get slotDuration() {
    return slotDuration;
  }

  protected get slotLabelFormat() {
    return slotLabelFormat;
  }

  protected get columnsHeaderFormat() {
    return columnsHeaderFormat;
  }

  protected get calendarPlugins() {
    return calendarPlugins;
  }

  protected get eventTypeLabels() {
    return EventTypeLabels;
  }

  protected get activeOrganization(): string {
    return this.event?.organization_id || '';
  }

  protected get eventsFilter() {
    const filter: {[key: string]: any} = {
      types: [
        EventType.APPOINTMENT,
        EventType.SICK,
        EventType.HOLIDAY,
        EventType.MEETING,
        EventType.HOUSEVISIT,
        EventType.SPEAKINGROOM,
        EventType.OTHER,
      ],
      not_cancelled: true,
      users: this.availableUsers.map((user: CalendarUser) => user.id),
    };

    filter.period = [this.event.date, this.event.date];

    return filter;
  }

  protected get werkvoorbereidingPlanningComment(): string | null {
    if (! this.report || ! this.report.answers) {
      return null;
    }

    const answer = this.report.answers.find((answer: Answer) => answer.key === 'WVBPlanComment');

    if (! answer || ! answer.value) {
      return null;
    }

    return answer.value;
  }

  // #endregion

  // #region @Watchers

  // #endregion
  @Watch('selectedUserId')
  protected selectedExpertIdChanged() {
    if (this.selectedUserId) {
      this.getAvailableUser();
    }
  }
}
