import { Vue, Component, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { AppointmentType, Event, EventIncludes } from '@/models/Event';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { uniqBy } from 'lodash';
import { MeetingStatus, meetingStatusColor, meetingStatusLabels, MeetingAppInfo } from '@/models/Meeting';

@Component<ReportVideoAppointment>({})
export default class ReportVideoAppointment extends Vue {
  @Prop()
  protected report!: Report;

  protected isLoading = false;

  protected events: Event[] = [];

  protected selectedEvent: Event | null = null;

  protected appInfo: MeetingAppInfo | null = null;

  protected mounted() {
    if (! this.report) {
      return;
    }

    this.fetchEvents();
  }

  protected async fetchEvents() {
    this.isLoading = true;
    const events: Event[] = await new Event()
      .filter({
        report: this.report.uuid,
        appointment_type: AppointmentType.OPNAME_OP_AFSTAND,
      })
      .sort('starts_at', 'ASC')
      .include([EventIncludes.MEETING, EventIncludes.USER, EventIncludes.MEDIATOR, EventIncludes.PARTICIPANTS])
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.events = uniqBy(events, 'meeting.id');
    const firstEventWithLoginCode = this.events.find((event) => event.meeting?.app?.login_code);
    console.log('firstEventWithLoginCode', firstEventWithLoginCode);
    this.appInfo = firstEventWithLoginCode ? firstEventWithLoginCode.meeting?.app as MeetingAppInfo : null;
    this.isLoading = false;
  }

  protected statusColor(event: Event): string {
    return event.meeting?.status ? meetingStatusColor[event.meeting.status] : '';
  }

  protected statusLabel(event: Event): string {
    return event.meeting?.status ? meetingStatusLabels[event.meeting.status] : '';
  }

  protected appointmentButtonText(event: Event) {
    if (! this.isMeetingStatusOpen(event)) {
      return 'samenvatting bekijken';
    }

    return 'Video afspraak starten';
  }

  protected isMeetingStatusOpen(event: Event) {
    return event?.meeting?.status === MeetingStatus.OPEN;
  }

  protected isMeetingCanceled(event: Event) {
    return event?.meeting?.status === MeetingStatus.CANCELLED;
  }

  protected isDisplayingMeetingButton(event: Event) {
    if (this.isMeetingCanceled(event)) {
      return false;
    }

    if (this.$store.state.isServiceOrganization) {
      return this.isMeetingStatusOpen(event);
    }

    return true;
  }
}
