var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.event
    ? _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          _c("template", { slot: "header" }, [_vm._v("Deskundige wijzigen")]),
          _c(
            "template",
            { slot: "content" },
            [
              _c(
                "v-layout",
                {
                  staticClass: "scroll-container",
                  attrs: { "justify-end": "", "align-center": "" }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "fill-height": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _vm.isLoading
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("LoaderCard", {
                                    attrs: {
                                      flat: "",
                                      type: "spinner--center",
                                      minHeight: "300px"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isLoading
                            ? [
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "custom-filter ma-0",
                                    attrs: { xs2: "", shrink: "" }
                                  },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.users,
                                        label: "Kies een deskundige",
                                        "item-text": "name",
                                        "item-value": "id",
                                        "hide-details": "",
                                        placeholder: "Kies een deskundige",
                                        "search-input": _vm.expertSearch,
                                        "prepend-icon": "assignment_ind",
                                        clearable: "",
                                        solo: "",
                                        flat: ""
                                      },
                                      on: {
                                        "click:clear": function($event) {
                                          return _vm.resetCalendar()
                                        },
                                        "update:searchInput": function($event) {
                                          _vm.expertSearch = $event
                                        },
                                        "update:search-input": function(
                                          $event
                                        ) {
                                          _vm.expertSearch = $event
                                        },
                                        keyup: _vm.debouncedSearch
                                      },
                                      model: {
                                        value: _vm.selectedUserId,
                                        callback: function($$v) {
                                          _vm.selectedUserId = $$v
                                        },
                                        expression: "selectedUserId"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "mb-3": "" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "weekChangeContainer" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "weekChange weekChange__prev",
                                            class: {
                                              disabled: !_vm.canSelectPrevious
                                            },
                                            on: { click: _vm.previous }
                                          },
                                          [_vm._v(" keyboard_arrow_left ")]
                                        ),
                                        _c(
                                          "h1",
                                          {
                                            staticClass:
                                              "elementSubTitle elementSubTitle__accent"
                                          },
                                          [_vm._v(_vm._s(_vm.switcherLabel))]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "weekChange weekChange__next",
                                            class: {
                                              disabled: !_vm.canSelectNext
                                            },
                                            on: { click: _vm.next }
                                          },
                                          [_vm._v(" keyboard_arrow_right ")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm.historicalDamages.length
                                  ? _c(
                                      "v-layout",
                                      {
                                        staticClass:
                                          "filterContainer filterContainer__historical",
                                        attrs: {
                                          wrap: "",
                                          "py-2": "",
                                          "pl-3": "",
                                          "pr-2": "",
                                          "mb-0": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { shrink: "", "mr-2": "" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "historical-damages--header"
                                              },
                                              [
                                                _vm._v(
                                                  "Deskundige uit historische dossiers:"
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._l(_vm.historicalDamages, function(
                                          historicalDamage
                                        ) {
                                          return _c(
                                            "v-flex",
                                            {
                                              key: historicalDamage.uuid,
                                              attrs: { shrink: "", "mr-3": "" }
                                            },
                                            [
                                              historicalDamage.answers &&
                                              historicalDamage.answers
                                                .historicalReport_Naamexpert
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "historical-damages--expert"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            historicalDamage
                                                              .answers
                                                              .historicalReport_Naamexpert
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              historicalDamage.answers &&
                                              historicalDamage.answers
                                                .historicalReport_CaseNumber
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "historical-damages--report"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " - " +
                                                          _vm._s(
                                                            historicalDamage
                                                              .answers
                                                              .historicalReport_CaseNumber
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm.werkvoorbereidingPlanningComment
                                  ? _c(
                                      "v-layout",
                                      {
                                        staticClass:
                                          "filterContainer filterContainer__historical",
                                        attrs: {
                                          wrap: "",
                                          "py-2": "",
                                          "pl-3": "",
                                          "pr-2": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { shrink: "", "mr-2": "" } },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.werkvoorbereidingPlanningComment
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.availableUsers && _vm.availableUsers.length
                                  ? _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c(
                                          "v-layout",
                                          _vm._l(_vm.availableUsers, function(
                                            visibleUser,
                                            index
                                          ) {
                                            return _c(
                                              "v-flex",
                                              {
                                                key: index,
                                                staticClass:
                                                  "calendarContainer elementPanel--shadowed",
                                                class: {
                                                  selected: _vm.isSelectedUser(
                                                    visibleUser
                                                  )
                                                },
                                                attrs: { "pa-4": "", xs4: "" }
                                              },
                                              [
                                                _vm.loadMap[visibleUser.id]
                                                  ? _c("v-progress-linear", {
                                                      staticClass:
                                                        "calendarLoader",
                                                      attrs: {
                                                        color: "#67A7C4",
                                                        indeterminate: true
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "h2",
                                                  {
                                                    staticClass:
                                                      "elementSubTitle user-header mb-3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          visibleUser
                                                            ? visibleUser.name
                                                            : "Geen naam"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "MiButton",
                                                  {
                                                    attrs: {
                                                      condensed: "",
                                                      color: "primary",
                                                      small: "true"
                                                    },
                                                    nativeOn: {
                                                      click: function($event) {
                                                        return _vm.selectUser(
                                                          visibleUser
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.isSelectedUser(
                                                            visibleUser
                                                          )
                                                            ? "Geselecteerd"
                                                            : "Selecteer Deskundige"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                visibleUser
                                                  ? _c("FullCalendar", {
                                                      ref:
                                                        "fullcalendar-" +
                                                        visibleUser.id,
                                                      refInFor: true,
                                                      attrs: {
                                                        plugins:
                                                          _vm.calendarPlugins,
                                                        defaultDate:
                                                          _vm.event.date,
                                                        defaultView:
                                                          "timeGridDay",
                                                        nowIndicator: true,
                                                        dragScroll: true,
                                                        selectable: false,
                                                        selectMirror: true,
                                                        editable: false,
                                                        slotDuration:
                                                          _vm.slotDuration,
                                                        slotLabelFormat:
                                                          _vm.slotLabelFormat,
                                                        columnHeaderHtml: _vm.columnsHeaderHtml(
                                                          visibleUser
                                                        ),
                                                        allDaySlot: false,
                                                        eventOverlap: false,
                                                        selectOverlap: false,
                                                        locale: "nl",
                                                        header: true,
                                                        height: 500,
                                                        events:
                                                          visibleUser.events,
                                                        businessHours:
                                                          visibleUser.businessHours,
                                                        selectConstraint:
                                                          "businessHours",
                                                        eventConstraint:
                                                          "businessHours",
                                                        minTime: "06:00",
                                                        maxTime: "20:00",
                                                        firstDay: 1
                                                      },
                                                      on: {
                                                        eventRender:
                                                          _vm.renderEvent
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              !_vm.isLoading
                ? _c(
                    "v-layout",
                    { attrs: { "justify-end": "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          [
                            _c(
                              "span",
                              {
                                staticClass: "fadedText link mr-3",
                                on: { click: _vm.close }
                              },
                              [_vm._v("Annuleren")]
                            ),
                            _c(
                              "MiButton",
                              {
                                attrs: { color: "primary", small: "true" },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.update($event)
                                  }
                                }
                              },
                              [_vm._v("Deskundige aanpassen")]
                            )
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }