import { Component, Vue, Prop } from 'vue-property-decorator';
import { Report as ReportModel } from '@/models/Report';
import { Options } from '@/components/mi-dialog/MiDialog';

@Component<InvestigationDialog>({})
export default class InvestigationDialog extends Vue {
  public $pageTitle = 'Onderzoek';

  @Prop()
  protected report!: ReportModel;

  @Prop()
  protected type!: string;

  protected isLoading = false;

  protected typeLabels: {[key: string]: string} = {
    followUpInvestigation: 'Vervolgopname',
    furtherInvestigation: 'Nader onderzoek',
    moreInvestigation: 'Aanvullend onderzoek',
    reInvestigation: 'Heropname',
  }

  protected rpcSignature: {[key: string]: string} = {
    followUpInvestigation: 'additional-visit:create',
    furtherInvestigation: 'further-investigation:create',
    moreInvestigation: 'more-investigation:create',
    reInvestigation: 'heropname:create',
  }

  protected mounted() {
    this.$pageTitle = `${this.typeLabels[this.type]} aanvragen`;
  }

  protected close() {
    this.$emit('input', false);
  }

  protected update() {
    this.$emit('submitted');
    this.close();
  }

  protected get confirmDialogOptions(): Options {
    return {
      title: `${this.typeLabels[this.type]} aangevraagd`,
      text: `${this.typeLabels[this.type]} succesvol aangevraagd`,
      type: 'success',
      buttons: {
        confirm: {
          text: 'Sluiten',
          color: 'success',
        },
      },
    };
  }
}
