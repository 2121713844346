var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "v-layout",
            [
              _vm.events && _vm.events.length
                ? _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c("h3", { staticClass: "mb-2" }, [
                          _vm._v("Opname op afstand")
                        ]),
                        _c(
                          "v-layout",
                          [
                            _vm.appInfo && _vm.appInfo.login_code
                              ? _c("v-flex", { attrs: { shrink: "" } }, [
                                  _c("p", { staticClass: "label mb-0" }, [
                                    _vm._v("Toegangscode:")
                                  ]),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "value mb-0 disabled font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.appInfo.login_code || "")
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.appInfo && _vm.appInfo.post_code
                              ? _c("v-flex", { attrs: { shrink: "" } }, [
                                  _c("p", { staticClass: "label mb-0" }, [
                                    _vm._v("Postcode:")
                                  ]),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "value mb-0 disabled font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.appInfo.post_code || "")
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._l(_vm.events, function(event, index) {
                          return _c(
                            "v-layout",
                            {
                              key: index,
                              staticClass: "ooa-container py-2",
                              attrs: { "items-center": "", "align-center": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "ooa-icon-container px-3",
                                  attrs: { shrink: "", "items-center": "" }
                                },
                                [_c("v-icon", [_vm._v("voice_chat")])],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    "items-center": "",
                                    "fill-height": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "ooa-title",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("dateFormat")(event.date)
                                              ) +
                                              " | " +
                                              _vm._s(
                                                _vm._f("formatTime")(
                                                  event.starts_at
                                                )
                                              ) +
                                              " - " +
                                              _vm._s(
                                                _vm._f("formatTime")(
                                                  event.ends_at
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "span",
                                          {
                                            class:
                                              "statusPill statusBackgroundColor--" +
                                              _vm.statusColor(event)
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.statusLabel(event)) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.isDisplayingMeetingButton(event)
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass: "px-3",
                                      attrs: { shrink: "", "items-center": "" }
                                    },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          staticClass: "mr-3",
                                          attrs: {
                                            condensed: "",
                                            color: "secondary",
                                            small: "true",
                                            icon: "",
                                            to: "/meeting/" + event.meeting.id
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.appointmentButtonText(event)
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }